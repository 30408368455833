<template>
  <div class="container">
    <a-row :gutter="[24, 0]" class="headBox">
      <!-- 柱状图背景 -->
      <!-- <div class="barBg" /> -->
      <a-col :xs="24" :sm="24" :lg="11" class="headLeft">
        <!-- <div class="left">{{ $t('about.lht') }}</div>
        <div class="center">{{ $t('about.zyhs') }}</div>
        <div class="right">{{ $t('about.znlhzz') }}</div> -->
      </a-col>
      <a-col :xs="24" :sm="24" :lg="{span: 11, offset: 1}">
        <a-form-model ref="ruleForm" layout="horizontal" :model="form">
          <div class="formBox">
            <div class="form_head">
              <img src="@/assets/images/form_rabbit.png" style="width: 2em; height: 2em">
              <span>#{{ $t('about.wxjslxzc') }}</span>
            </div>
            <div class="applicationBox">
              <div class="apcHead">{{ $t('about.tksq') }}</div>
              <div class="stepBox">
                <div class="stepItems" :class="step === 1 ? 'active' : false">
                  <span class="s_num">1</span>
                  <span>{{ $t('about.ybxx') }}</span>
                </div>
                <div class="stepItems" :class="step === 2 ? 'active' : false">
                  <span class="s_num">2</span>
                  <span>{{ $t('about.dkzl') }}</span>
                </div>
                <div class="stepItems" :class="step === 3 ? 'active' : false">
                  <span class="s_num">3</span>
                  <span>{{ $t('about.gzzx') }}</span>
                </div>
              </div>
              <!-- 步骤一 -->
              <a-row v-show="step === 1" :gutter="[24, 0]">
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="name"
                    :rules="{
                      required: step === 1,
                      message: `${$t('about.qtx')}${$t('about.name')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.name')"
                  >
                    <a-input v-model="form.name" :placeholder="$t('about.name')" />
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="phone"
                    :rules="{
                      required: step === 1,
                      message: `${$t('about.qtx')}${$t('about.phone')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.phone')"
                  >
                    <a-input v-model="form.phone" :placeholder="$t('about.phone')" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="email"
                    :rules="{
                      required: step === 1,
                      message: `${$t('about.qtx')}${$t('about.email')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.email')"
                  >
                    <a-input v-model="form.email" :placeholder="$t('about.email')" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="checked"
                    :rules="{
                      required: step === 1,
                      message: `${$t('about.qxxydjmbxl')}${$t('about.grzlsytl')}`,
                      trigger: 'change'
                    }"
                  >
                    <a-checkbox-group v-model="form.checked">
                      <a-checkbox value="1" class="checkSize">
                        {{ $t('about.agree') }}<span class="checkBlue" @click="$router.push('/policy')">{{ $t('about.grzlsytl') }}。</span>
                      </a-checkbox>
                    </a-checkbox-group>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <!-- 步骤二 -->
              <a-row v-show="step === 2" :gutter="[24, 0]">
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="purpose.default"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qxz')}${$t('about.purposeLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.purposeLabel')"
                  >
                    <a-select v-model="form.purpose.default" :placeholder="$t('about.purposeLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.purposeLabel') }}</span>
                        <a-select-option
                          v-for="items in form.purpose.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="price"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qtx')}${$t('about.priceLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.priceLabel')"
                  >
                    <a-input-number v-model="form.price" :min="1" :placeholder="$t('about.priceLabel')" style="width: 100%" />
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="period.default"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qxz')}供款期`,
                      trigger: 'blur'
                    }"
                    label="供款期"
                  >
                    <a-select v-model="form.period.default" placeholder="供款期">
                      <a-select-opt-group>
                        <span slot="label">供款期</span>
                        <a-select-option
                          v-for="items in form.period.arr"
                          :key="items.value"
                          :value="items.value"
                        >
                          {{ items.name }}{{ $t('about.ge') }}月
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="sex.default"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qxz')}性別`,
                      trigger: 'blur'
                    }"
                    label="性別"
                  >
                    <a-select v-model="form.sex.default" placeholder="性別">
                      <a-select-opt-group>
                        <span slot="label">性別</span>
                        <a-select-option
                          v-for="items in form.sex.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="cardNumber"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qtx')}${$t('about.cardNumberLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.cardNumberLabel')"
                  >
                    <a-input v-model="form.cardNumber" :placeholder="$t('about.cardNumberLabel')" />
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="birthday"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qxz')}出生日期`,
                      trigger: 'change'
                    }"
                    label="出生日期"
                  >
                    <a-date-picker
                      v-model="form.birthday"
                      :format="'DD/MM/YYYY'"
                      :placeholder="$t('about.qxz')+'出生日期'"
                      style="width: 100%"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24" class="someHead">{{ $t('about.jzzl') }}</a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="area.default"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qxz')}${$t('about.areaLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.areaLabel')"
                  >
                    <a-select v-model="form.area.default" :placeholder="$t('about.qxz')+$t('about.areaLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.areaLabel') }}</span>
                        <a-select-option
                          v-for="items in form.area.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="region.default"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qxz')}${$t('about.regionLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.regionLabel')"
                  >
                    <a-select v-model="form.region.default" :placeholder="$t('about.qxz')+$t('about.regionLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.regionLabel') }}</span>
                        <a-select-option
                          v-for="items in form.region.regionJson[form.area.default || 'arr']"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="address"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qtx')}住宅地址`,
                      trigger: 'blur'
                    }"
                    label="住宅地址"
                  >
                    <a-input v-model="form.address" :placeholder="$t('about.qtx')+'住宅地址'" />
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="situation.default"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qxz')}${$t('about.situationLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.situationLabel')"
                  >
                    <a-select v-model="form.situation.default" :placeholder="$t('about.qtx')+$t('about.situationLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.situationLabel') }}</span>
                        <a-select-option
                          v-for="items in form.situation.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="monthContribution"
                    :rules="{
                      required: step === 2,
                      message: `${$t('about.qtx')}按揭供款/租金`,
                      trigger: 'blur'
                    }"
                    label="每月按揭供款/租金"
                  >
                    <a-input-number v-model="form.monthContribution" :placeholder="$t('about.qtx')+'按揭供款/租金'" style="width: 100%" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <!-- 步骤三 -->
              <a-row v-show="step === 3" :gutter="[24, 0]">
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="working.default"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qxz')}${$t('about.workingLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.workingLabel')"
                  >
                    <a-select v-model="form.working.default" :placeholder="$t('about.qxz')+$t('about.workingLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.workingLabel') }}</span>
                        <a-select-option
                          v-for="items in form.working.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="monthIncome"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qtx')}每月薪金或收入`,
                      trigger: 'blur'
                    }"
                    label="每月薪金或收入"
                  >
                    <a-input-number v-model="form.monthIncome" placeholder="每月薪金或收入" style="width: 100%" />
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="occupation.default"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qxz')}${$t('about.occupationLabel')}`,
                      trigger: 'blur'
                    }"
                    :label="$t('about.occupationLabel')"
                  >
                    <a-select v-model="form.occupation.default" :placeholder="$t('about.qxz')+$t('about.occupationLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.occupationLabel') }}</span>
                        <a-select-option
                          v-for="items in form.occupation.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24" class="someHead">
                  {{ $t('about.szspwjhtgcy') }}<br>
                  <span>{{ $t('about.scndwjjkjkspsd') }}</span>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="identityImg"
                    :label="$t('about.identityLabel')"
                  >
                    <div class="uploadBox" :class="form.identityImg.length > 0 ? 'hasupload' : false">
                      <a-upload-dragger
                        :file-list="form.identityImg"
                        name="file"
                        :custom-request="customRequest"
                        list-type="picture"
                        @change="uploadChange($event, 'identityImg')"
                      >
                        <p class="ant-upload-drag-icon">
                          <a-icon type="inbox" />
                        </p>
                        <p class="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </a-upload-dragger>
                    </div>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="addressImg"
                    :label="$t('about.addressLabel')"
                  >
                    <div class="uploadBox" :class="form.addressImg.length > 0 ? 'hasupload' : false">
                      <a-upload-dragger
                        :file-list="form.addressImg"
                        name="file"
                        :custom-request="customRequest"
                        list-type="picture"
                        @change="uploadChange($event, 'addressImg')"
                      >
                        <p class="ant-upload-drag-icon">
                          <a-icon type="inbox" />
                        </p>
                        <p class="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </a-upload-dragger>
                    </div>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="recordImg"
                    :label="$t('about.recordLabel')"
                  >
                    <div class="uploadBox" :class="form.recordImg.length > 0 ? 'hasupload' : false">
                      <a-upload-dragger
                        :file-list="form.recordImg"
                        name="file"
                        :custom-request="customRequest"
                        list-type="picture"
                        @change="uploadChange($event, 'recordImg')"
                      >
                        <p class="ant-upload-drag-icon">
                          <a-icon type="inbox" />
                        </p>
                        <p class="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </a-upload-dragger>
                    </div>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="residenceImg"
                    :label="$t('about.residenceLabel')"
                  >
                    <div class="uploadBox" :class="form.residenceImg.length > 0 ? 'hasupload' : false">
                      <a-upload-dragger
                        :file-list="form.residenceImg"
                        name="file"
                        :custom-request="customRequest"
                        list-type="picture"
                        @change="uploadChange($event, 'residenceImg')"
                      >
                        <p class="ant-upload-drag-icon">
                          <a-icon type="inbox" />
                        </p>
                        <p class="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </a-upload-dragger>
                    </div>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24" class="someHead">
                  {{ $t('about.nyldx') }}
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="hasRelation"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qxz')}`,
                      trigger: 'change'
                    }"
                    :label="$t('about.nsf')"
                    class="whiteSpace"
                  >
                    <a-radio-group v-model="form.hasRelation">
                      <a-radio value="0" style="display: block; height: 30px; lineHeight: 30px">
                        否
                      </a-radio>
                      <a-radio value="1" style="display: block; height: 30px; lineHeight: 30px">
                        是
                      </a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                </a-col>
                <a-col v-show="form.hasRelation === '1'" :span="24">
                  <a-form-model-item
                    prop="RelationName"
                    :rules="{
                      required: step === 3 && form.hasRelation === '是',
                      message: `${$t('about.qtx')}`,
                      trigger: 'blur'
                    }"
                  >
                    <a-input
                      v-model="form.RelationName"
                      :placeholder="$t('about.qlcxm')"
                      :max-length="100"
                      style="width: 100%"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="24" class="someHead" style="color: #000B8C; margin: 0">
                  <span>重要提示</span><br>
                  <span>{{ $t('about.tzbgs') }}</span>
                </a-col>
                <a-col :span="24" class="someHead">
                  {{ $t('about.contactLabel') }}
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="contact.default"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qxz')}${$t('about.contactLabel')}`,
                      trigger: 'change'
                    }"
                    :label="$t('about.contactLabel')"
                  >
                    <a-select v-model="form.contact.default" :placeholder="$t('about.qtx')+$t('about.contactLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.contactLabel') }}</span>
                        <a-select-option
                          v-for="items in form.contact.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :xs="24" :sm="24" :lg="12">
                  <a-form-model-item
                    prop="contactTime.default"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qxz')}${$t('about.contactTimeLabel')}`,
                      trigger: 'change'
                    }"
                    :label="$t('about.contactTimeLabel')"
                  >
                    <a-select v-model="form.contactTime.default" :placeholder="$t('about.qxz')+$t('about.contactTimeLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.contactTimeLabel') }}</span>
                        <a-select-option
                          v-for="items in form.contactTime.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="messageRoute.default"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qxz')}${$t('about.tujingLabel')}`,
                      trigger: 'change'
                    }"
                    :label="$t('about.messageRouteLabel')"
                  >
                    <a-select v-model="form.messageRoute.default" :placeholder="$t('about.qxz')+$t('about.tujingLabel')">
                      <a-select-opt-group>
                        <span slot="label">{{ $t('about.messageRouteLabel') }}</span>
                        <a-select-option
                          v-for="items in form.messageRoute.arr"
                          :key="items"
                          :value="items"
                        >
                          {{ items }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item
                    prop="agreeChecked"
                    :rules="{
                      required: step === 3,
                      message: `${$t('about.qxz')}`,
                      trigger: 'change'
                    }"
                  >
                    <a-checkbox-group v-model="form.agreeChecked">
                      <a-checkbox value="1" class="checkSize">
                        {{ $t('about.brdsfrs') }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <div class="stepButton">
              <a-button v-show="step > 1" type="primary" shape="round" class="next" style="margin-right: auto" @click="checkStepPre">{{ $t('about.pre') }}</a-button>
              <a-button type="primary" shape="round" class="next" style="margin-left: auto" @click="checkStepNext">{{ step === 3 ? $t('about.submit') : $t('about.next') }}</a-button>
            </div>
            <div class="phoneBox">
              <div class="applicating">
                <img src="@/assets/images/applicating.png" style="width: 1.5em; height: 1.5em">
                <span>2 {{ $t('about.applicating') }}</span>
              </div>
              <div class="phone">{{ $t('about.fzrpzhm') }}：1145/2021</div>
            </div>
          </div>
        </a-form-model>
      </a-col>
    </a-row>
    <div class="someDivBg" />
    <div class="whySelectBox">
      <div class="topBox">
        <img src="@/assets/images/aboutIcon.png">
        {{ $t('about.why') }}
      </div>
      <div class="topDesc">
        {{ $t('about.desc') }}
      </div>
    </div>
    <div class="paddingPub tabs">
      <div class="sixBox">
        <div class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g1.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.lht.title') }}</p>
            <span>{{ $t('about.fourBox.lht.desc') }}</span>
          </div>
        </div>
        <div class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g2.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.srdk.title') }}</p>
            <span>{{ $t('about.fourBox.srdk.desc') }}</span>
          </div>
        </div>
        <div class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g3.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.jyzh.title') }}</p>
            <span>{{ $t('about.fourBox.jyzh.desc') }}</span>
          </div>
        </div>
        <div class="sixItems">
          <div class="imgBox">
            <img src="@/assets/images/g4.png">
          </div>
          <div class="desc">
            <p>{{ $t('about.fourBox.yalyjj.title') }}</p>
            <span>{{ $t('about.fourBox.yalyjj.desc') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import {
  uploadRequest,
  submitApply
} from '../utils/request'
export default {
  name: 'About',
  data() {
    const regionJson = this.$i18n.messages[this.$lan].about.region
    return {
      activeUploading: 'identify',
      form: {
        checked: [],
        agreeChecked: [],
        name: '',
        phone: '',
        email: '',
        RelationName: undefined,
        purpose: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.purpose
        },
        period: {
          default: undefined,
          arr: [{
            name: '12',
            value: '12'
          }, {
            name: '24',
            value: '24'
          }, {
            name: '36',
            value: '36'
          }, {
            name: '48',
            value: '48'
          }, {
            name: '60',
            value: '60'
          }, {
            name: '90',
            value: '90'
          }, {
            name: '120',
            value: '120'
          }, {
            name: '180',
            value: '180'
          }, {
            name: '240',
            value: '240'
          }, {
            name: '300',
            value: '300'
          }]
        },
        price: undefined,
        sex: {
          default: undefined,
          arr: ['男', '女']
        },
        area: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.area
        },
        region: {
          default: undefined,
          arr: [],
          regionJson
        },
        cardNumber: '',
        birthday: '',
        working: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.working
        },
        address: '',
        situation: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.situation
        },
        monthIncome: undefined,
        monthContribution: undefined,
        occupation: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.occupation
        },
        identityImg: [],
        addressImg: [],
        recordImg: [],
        residenceImg: [],
        hasRelation: undefined,
        contact: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.contact
        },
        contactTime: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.contactTime
        },
        messageRoute: {
          default: undefined,
          arr: this.$i18n.messages[this.$lan].about.messageRoute
        }
      },
      step: 1
    }
  },
  methods: {
    backTop() {
      var timer = setInterval(function() {
        const osTop = document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 50)
    },
    checkStepPre() {
      this.step -= 1
      this.backTop()
    },
    checkStepNext() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.step === 3) {
            const paramJson = {
              name: this.form.name,
              sex: this.form.sex.default,
              phone: this.form.phone,
              work: this.form.working.default,
              identity: this.form.cardNumber,
              occupation: this.form.occupation.default,
              email: this.form.email,
              birth: moment(this.form.birthday._d).format('YYYY-MM-DD'),
              monthIncome: this.form.monthIncome,
              loanAmount: this.form.price,
              loanPurpose: this.form.purpose.default,
              residence: this.form.period.default,
              address: this.form.address,
              monthContribution: this.form.monthContribution,
              area: this.form.area.default,
              situation: this.form.situation.default,
              contact: this.form.contact.default,
              contactTime: this.form.contactTime.default,
              messageRoute: this.form.messageRoute.default,
              hasRelation: this.form.hasRelation,
              identityImg: this.form.identityImg.length ? this.form.identityImg[0].url : undefined,
              addressImg: this.form.addressImg.length ? this.form.addressImg[0].url : undefined,
              recordImg: this.form.recordImg.length ? this.form.recordImg[0].url : undefined,
              residenceImg: this.form.residenceImg.length ? this.form.residenceImg[0].url : undefined,
              RelationName: this.form.RelationName
            }
            submitApply(paramJson).then(res => {
              if (res.head.success) {
                this.$message.success('success')
                setTimeout(() => {
                  this.$router.push({
                    path: '/result'
                  }, 1500)
                })
              } else {
                this.$message.error(res.head.message)
              }
            })
            return
          }
          this.step += 1
          this.backTop()
        }
      })
    },
    uploadChange(info, something) {
      let fileList = info.fileList
      fileList = fileList.slice(-1)
      this.activeUploading = something
      switch (something) {
        case 'identityImg':
          this.form.identityImg = fileList
          break
        case 'addressImg':
          this.form.addressImg = fileList
          break
        case 'recordImg':
          this.form.recordImg = fileList
          break
        case 'residenceImg':
          this.form.residenceImg = fileList
          break
      }
    },
    customRequest(file) {
      const formData = new FormData()
      formData.append('file', file.file)
      uploadRequest(formData).then(res => {
        if (res.head.success) {
          const json = {
            uid: file.file.uid,
            name: file.file.name,
            status: 'done',
            url: res.body.img,
            thumbUrl: res.body.img
          }
          switch (this.activeUploading) {
            case 'identityImg':
              this.form.identityImg = [json]
              break
            case 'addressImg':
              this.form.addressImg = [json]
              break
            case 'recordImg':
              this.form.recordImg = [json]
              break
            case 'residenceImg':
              this.form.residenceImg = [json]
              break
          }
        } else {
          this.$message.error(res.head.message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.someHead{
  margin: 14px 0;
  font-size: 22px;
  font-weight: bold;
  span{
    font-size: 14px;
  }
}
.paddingPub{
  padding-left: 4rem;
  padding-right: 4rem;
}
.headBox{
  margin: 0 !important;
  background: #000B8C;
  //padding: 9% 4% 4% 8%;
  padding: 9% 4% 8% 8%;
  color: #fff;
  position: relative;
  overflow: hidden;
  background: url("../assets/images/applypage.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
  //&::before{
    //width: 286px;
    //height: 386px;
    //background: url("../assets/images/about_radius.png") 0 -72px no-repeat;
    //background-size: 100%;
    //position: absolute;
    //top: 0;
    //right: 0;
  //}
  .barBg{
    width: 45.83%;
    height: 60%;
    position: absolute;
    bottom: 0;
    left: 0;
    //&::before{
     // width: 100%;
      //height: 100%;
      //content: '';
      //background: url("../assets/images/about_bar.png") 0 bottom no-repeat;
      //background-size: 65%;
      //position: absolute;
      //left: 26%;
    //}
    //&::after{
      //width: 100%;
      //height: 100%;
      //content: '';
      //background: url("../assets/images/about_rabbit.png") 0 bottom no-repeat;
      //background-size: 36%;
      //position: absolute;
      //bottom: 36%;
      //left: 41%;
    //}
  }
  .headLeft{
    height: 100%;
    .left{
      text-align: left;
      font-size: 32px;
    }
    .center{
      text-align: center;
      font-size: 58px;
      font-weight: bold;
    }
    .right{
      text-align: right;
      font-size: 32px;
    }
  }
  .formBox{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2% 7% 6%;
    position: relative;
    z-index: 1;
    &::after{
      width: 104%;
      height: 106%;
      content: '';
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 3px 3px 25px rgba(135, 150, 170, 0.25);
      position: absolute;
      top: -4%;
      left: -2%;
      z-index: -1;
    }
    .form_head{
      span{
        font-size: 16px;
        color: #000000;
        margin-left: 1rem;
      }
    }
    .applicationBox{
      .apcHead{
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        margin: 5% 0 3%;
      }
    }
    .stepBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #EBEEF0;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 3%;
      .stepItems{
        width: 33.3333%;
        text-align: center;
        cursor: pointer;
        padding: 18px 0;
        font-size: 16px;
        .s_num{
          display: inline-block;
          border-radius: 50%;
          background: #A3A7BA;
          padding: 1px 9px;
          color: #ffffff;
          margin-right: 14px;
        }
        &.active{
          background: #DCEEFF;
          color: #000B8C;
          .s_num{
            background: #000B8C;
          }
        }
        // &:hover .s_num{
        //   background: #000B8C;
        // }
      }
    }
    .checkSize{
      font-size: 12px;
      .checkBlue{
        color: #000B8C;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .phoneBox{
      .applicating{
        margin-top: 20px;
        margin-bottom: 12px;
        span{
          color: #10CFC9;
          font-size: 16px;
          margin-left: 20px;
        }
      }
      .phone{
        color: #000000;
      }
    }
    .next{
      padding: 0 36px;
    }
  }
}
.someDivBg{
  width: 100%;
  height: 1.3rem;
  background: rgba(0, 11, 140, 0.2);
}
.whySelectBox{
  text-align: center;
  padding-top: 8rem;
  padding-bottom: 3rem;
  line-height: 50px;
  .topBox{
    color: #000B8C;
    font-size: 42px;
    font-weight: bold;
    img{
      width: 2.4em;
      height: 2.4em;
      object-fit: contain;
      margin-top: -18px;
      margin-right: 12px;
    }
  }
  .topDesc{
    color: #727272;
    font-size: 20px;
  }
}
.stepButton{
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.tabs{
  padding-top: 10rem;
  padding-bottom: 10rem;
  background: #F5F9FD;
  .sixBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    .sixItems{
      width: 22%;
      height: 272px;
      cursor: pointer;
      position: relative;
      text-align: center;
      border: 1px solid #000B8B;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 24px 24px;
      margin-bottom: 3rem;
      .imgBox{
        width: 157px;
        height: 100px;
        align-self: flex-end;
        img{
          object-fit: contain;
        }
      }
      .desc{
        width: 100%;
        text-align: center;
        align-self: flex-end;
        font-weight: bold;
        line-height: 36px;
        p{
          font-size: 22px;
        }
        span{
          color: #777777;
        }
      }
      &:first-of-type{
        background-color: #000B8B;
        color: #ffffff;
        span{
          color: #CFCFCF;
        }
      }
      &:after{
        width: 100%;
        height: 100%;
        content: '';
        border: 1px solid #000B8B;
        border-radius: 10px;
        position: absolute;
        top: 9px;
        right: -9px;
        z-index: -1;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .headBox{
    padding: 9% 1% 4% 3%;
    .headLeft{
      .left{
        font-size: 30px;
      }
      .center{
        font-size: 50px;
      }
      .right{
        font-size: 30px;
      }
    }
    .barBg{
      &::before{
        background-size: 88%;
        left: 11%;
      }
      &::after{
        background-size: 42%;
        left: 36%;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .headBox{
    .headLeft{
      .center{
        font-size: 42px;
      }
    }
    .barBg{
      &::before{
        background-size: 88%;
      }
      &::after{
        background-size: 42%;
      }
    }
  }
  .tabs{
    .sixBox{
      .sixItems{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 996px) {
  .barBg,
  .headLeft,
  .headBox::before,
  .headBox .formBox::after{
    display: none;
  }
  .headBox{
    padding: 4% 1%;
    .formBox{
      .phoneBox{
        text-align: center;
        .applicating{
          img{
            width: 2.5em !important;
            height: 2.5em !important;
            margin-top: -15px;
          }
          span{
            font-size: 26px;
            font-weight: bold;
          }
        }
        .phone{
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
  }
  .whySelectBox{
    .topDesc{
      width: 90%;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 576px) {
  .whySelectBox{
    line-height: 34px;
    padding-top: 4rem;
    .topBox{
      font-size: 24px;
    }
    .topDesc{
      font-size: 18px;
    }
  }
  .tabs{
    padding: 3rem 2rem;
    .sixBox{
      .sixItems{
        margin-bottom: 2rem;
      }
    }
  }
  .headBox .formBox .stepBox .stepItems{
    .s_num{
      margin-bottom: 12px;
      margin-right: 0;
    }
    >span:last-child{
      display: block;
      font-size: 12px;
    }
  }
}
</style>

